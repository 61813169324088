import Q from "q";
import moment from "moment";
//改签订单详情
import trains_order_buyerChangeOrderDetail from "@/lib/data-service/trains/trains_order_buyerChangeOrderDetail.js";

// 取消改签
import trains_order_buyerCancelChangeOrder from "@/lib/data-service/trains/trains_order_buyerCancelChangeOrder.js";

// // 获取订单支付状态
import trains_order_changeOrderPaymentStatus from "@/lib/data-service/trains/trains_order_changeOrderPaymentStatus.js";
export default {
  data() {
    return {
      loading: true,
      detail: {
        trainsOrderChange: {},
      },
      timer: null,
    };
  },
  methods: {
    req_detail(changeNo) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_buyerChangeOrderDetail({ changeNo });
        })
        .then(function(res) {
          let detail = res;
          detail.trainsOrderChange.originalChangeStartDate = moment(detail.trainsOrderChange.originalChangeStartDate).format(
            "YYYY-MM-DD HH:mm"
          );

          detail.inChangePassengerList.forEach(function(item) {
            item.gmtStart = moment(item.gmtStart).format(
              "YYYY-MM-DD HH:mm"
            );

            if (item.passengerType) {
              switch (item.passengerType) {
                case 1:
                  item.passengerTypeText = "成人";
                  detail.adultPrice = item.ticketPrice;
                  break;
                case 2:
                  item.passengerTypeText = "儿童";
                  detail.childPrice = item.ticketPrice;
                  break;
                case 3:
                  item.passengerTypeText = "学生";
                  break;
                case 4:
                  item.passengerTypeText = "军残";
                  break;
              }
            }

            if (item.passengerCardType) {
              switch (parseInt(item.passengerCardType)) {
                case 1:
                  item.passengerCardTypeText = "身份证";
                  break;
                case 2:
                  item.passengerCardTypeText = "儿童无证件";
                  break;
                case 3:
                  item.passengerCardTypeText = "港澳通行证";
                  break;
                case 4:
                  item.passengerCardTypeText = "台湾通行证";
                  break;
                case 5:
                  item.passengerCardTypeText = "护照护照";
                  break;
              }
            }

            if (item.passengerCardNumber) {
              item.passengerCardNumberFront = item.passengerCardNumber.substr(
                0,
                4
              );
              item.passengerCardNumberEnding = item.passengerCardNumber.substr(
                -3,
                3
              );
            }

            if (item.ticketStatus) {
              switch (item.ticketStatus) {
                case 1:
                  item.ticketStatusText = "未出票";
                  break;
                case 2:
                  item.ticketStatusText = "待出票";
                  break;
                case 3:
                  item.ticketStatusText = "出票中";
                  break;
                case 4:
                  item.ticketStatusText = "出票失败";
                  break;
                case 5:
                  item.ticketStatusText = "退票已申请";
                  break;
                case 6:
                  item.ticketStatusText = "退票处理中";
                  break;
                case 7:
                  item.ticketStatusText = "退票完成";
                  break;
                case 8:
                  item.ticketStatusText = "退票失败";
                  break;
                case 9:
                  item.ticketStatusText = "改签已申请";
                  break;
                case 10:
                  item.ticketStatusText = "改签中";
                  break;
                case 11:
                  item.ticketStatusText = "改签完成";
                  break;
                case 12:
                  item.ticketStatusText = "改签失败";
                  break;
                case 13:
                  item.ticketStatusText = "改签已取消";
                  break;
              }
            }
          });
          _this.count_down(detail);
          _this.detail = detail;
          _this.timer = setInterval(function() {
            _this.count_down(_this.detail);
          }, 1000);
          _this.loading = false;
        });
    },
    req_cancel_change(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_buyerCancelChangeOrder(params);
        })
        .then(function(res) {
          _this.$message.success("已取消改签");
          _this.req_detail(_this.$route.query.changeNo);
        });
    },
    req_payment_status(params) {
      return Q.when().then(function() {
        return trains_order_changeOrderPaymentStatus(params);
      });
    },
    count_down(detail) {
      if (detail.trainsOrderChange.changeOderStatus === 1) {
        const gmtLastPayment = detail.trainsOrderChange.gmtLastPayment;
        const now = moment();

        const diff = moment(gmtLastPayment).diff(now, "seconds");
        if (diff >= 0) {
          detail.secondText = Math.floor(diff / 60) + "分" + (diff % 60) + "秒";
          if (diff === 0) this.req_detail(this.$route.query.changeNo);
        } else if (diff < 0) {
          detail.secondText = "00分00秒";
        }
      } else {
        detail.secondText = "--";
      }
    },
    cancel_change(changeNo) {
      const _this = this;
      this.$confirm("是否确定取消改签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.req_cancel_change({ changeNo });
        })
        .catch(() => {});
    },
    to_payment(orderNo) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_payment_status({ orderNo });
        })
        .then(function(res) {
          if (res.ifTimeout === 1) {
            _this.$router.push({
              name: "distributor-train-front-train-change-payment",
              query: { orderNo },
            });
          } else if (res.ifTimeout === 2) {
            _this.req_detail(_this.$route.query.changeNo);
          }
        });
    },
  },
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  activated() {
    document.documentElement.scrollTop = 0;
    this.req_detail(this.$route.query.changeNo);
  },
  deactivate() {
    clearInterval(this.timer);
  },
};
