const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 分销取消改签接口文档地址：http://192.168.0.11:9900/doc.html#/trains/order-controller/buyerCancelChangeOrderUsingPOST_1
const trains_order_buyerCancelChangeOrder = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/trains/order/buyerCancelChangeOrder',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = trains_order_buyerCancelChangeOrder;
